import { Routes } from '@angular/router';
import { environment } from '../environments/environment';

export const mainRoutes: Routes = [
    {
        path: '',
        redirectTo: environment.authRedirect,
        pathMatch: 'full',
    },
    {
        path: '',
        loadChildren: () => import('./app.routes'),
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth.routes'),
    },
];