<header id="header__layout" class="w-full bg-[#F1F4F6] lg:p-3 py-3 sm:px-5 px-3 relative z-[2] max-w-full overflow-y-hidden overflow-x-auto">
	<nav class="flex flex-wrap sm:justify-start justify-center gap-4 items-center md:w-[1440px] md:max-w-full md:mx-auto ">
		<a routerLink="/pending-payments" routerLinkActive #rla1="routerLinkActive" class="sm:w-max w-full">
			<button-cy type="button" addClass="uppercase w-max {{ rla1.isActive ? 'btn__primary' : 'btn__white-primary' }} sm:!w-max !w-full" label="Mis Pagos Pendientes" />
		</a>
		<a routerLink="/payments" routerLinkActive #rla2="routerLinkActive">
			<button-cy type="button" addClass="uppercase w-max {{ rla2.isActive ? 'btn__primary' : 'btn__white-primary' }}" label="Mi Historial de Pagos" />
		</a>
		<a routerLink="/payment-cards" routerLinkActive #rla3="routerLinkActive">
			<button-cy type="button" addClass="uppercase w-max {{ rla3.isActive ? 'btn__primary' : 'btn__white-primary' }}" label="Mis Tarjetas" />
		</a>
	</nav>
</header>